import React from 'react';
import devices from '../../assets/images/pics/devices.png';

const DevicesSection = () => {
  return (
    <section className="section-2">
      <div className="section-2__wrapper">
        <img src={devices} alt="devices" className="section-2__img" />
      </div>
    </section>
  );
};

export default DevicesSection;
