import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import image from '../../assets/images/pics/flow-illustration.png';

const RulesSection = () => {
  const { t } = useTranslation('download');
  return (
    <section className="section-3">
      <div className="container">
        <div className="section-3__wrapper">
          <div className="section-3__info">
            <h2 className="section-3__title">
              <Trans
                defaults="Your money <0>Your rules</0>"
                i18nKey="download:RulesSection.title"
                components={[<div className="section-3__title--violet" />]}
              />
            </h2>
          </div>
          <img src={image} alt="flow-illustration" className="section-3__img" />
        </div>
        <div className="section-3__article">
          <h3 className="section-3__subtitle u-color-success">
            {t('RulesSection.subtitle', 'Do more with your money')}
          </h3>
          <div className="section-3__text u-color-gray">
            {t(
              'RulesSection.description',
              `Divide your salary as soon as it comes in, refill your budgets,
            build up savings and many more features to come soon: connect
            investment accounts, pay your bills when your salary comes in, stay
            tuned!`
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RulesSection;
