import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { getFlowButtonHandler } from '../Dialog';
const QRCode = require('qrcode.react');

const DownloadSection = () => {
  const { t } = useTranslation('download');

  const [showQR, setDeeplink] = useState('');
  useEffect(() => {
    const ref = document.referrer;
    const search = new URLSearchParams(document.location.search).get('open');
    const target = ref?.startsWith('https://app.flowyour.money/') ? ref : search;
    if (target) setDeeplink(target);
  }, []);

  return (
    <section className="section-1">
      <div className="container">
        <div className="section-1__content u-spacing-large">
          <h2 className="section-1__title u-title-primary">
            <Trans
              defaults="<0>Download</0> the Flow app"
              i18nKey={`download:DownloadSection.${showQR ? 'showqr' : 'title'}`}
              components={[<div className="u-color-success" />]}
            />
          </h2>
          {showQR ? (
            <div style={{ padding: '4rem' }}>
              <QRCode size={256} value={showQR} />
            </div>
          ) : null}
          <div className="section-1__text u-text-primary u-color-gray">
            {t(
              'DownloadSection.description',
              'Start with automating your money today, download the Flow app and get started.'
            )}
          </div>

          <button
            className={`btn btn--primary${showQR ? ' qr' : ''}`}
            onClick={() => (showQR ? window.location.assign(showQR) : getFlowButtonHandler())}
          >
            {t(`download:DownloadSection.${showQR ? 'openApp' : 'buttonTitle'}`, 'Get the app')}
          </button>
          {/* <div className="section-1__group">
            <a
              href="https://apps.apple.com/nl/app/flow-money-automation/id1441919325?l=en"
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn--primary"
            >
              {t("DownloadSection.buttonTitle_1", "iPhone app")}
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=money.flowyour.flow&hl=en"
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn--primary"
            >
              {t("DownloadSection.buttonTitle_2", "Android app")}
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default DownloadSection;
