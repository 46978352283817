import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const BunqSection = () => {
  const { t } = useTranslation('download');
  return (
    <section className="section-4">
      <div className="container">
        <div className="section-4__content u-spacing-large">
          <h2 className="section-4__title u-title-primary">
            {t('BunqSection.title', 'Start automating your money today')}
          </h2>
          <div className="section-4__text u-text-primary">
            {t(
              'BunqSection.description',
              'Get started by opening a bunq account, then link it to Flow.'
            )}
          </div>
          <a
            href="https://www.getbunq.app/2G4NJSR/225JFQ/?uid=32"
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn--primary"
          >
            {t('BunqSection.buttonTitle', 'Open a bunq account')}
          </a>
        </div>
      </div>
    </section>
  );
};

export default BunqSection;
