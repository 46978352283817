import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import DownloadSection from '../components/download/DownloadSection';
import DevicesSection from '../components/download/DevicesSection';
import RulesSection from '../components/download/RulesSection';
import BunqSection from '../components/download/BunqSection';

const Download = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="Download Flow" />
      <main className="page-download">
        <DownloadSection />
        <DevicesSection />
        <RulesSection />
        <BunqSection />
      </main>
    </Layout>
  );
};

export default Download;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
